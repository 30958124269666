import React from 'react';

function CompanyParters({partner}){
	return(
		<div className='col-sm-4'>
			<img className="center-block img-responsive" src = {partner.image} height = '150px' width ='300px'/>
			<h2 className='text-center'>{partner.name}</h2>
			<p>{partner.info}</p>
		</div>
	);
}
export default CompanyParters