import React from 'react';

const PastProjects=({past})=>
		<div className='row row-striped'>
			<div className='col-sm-3'>
				<img className="center-block img-responsive" src = {past.Image} height = '150px' width ='300px'/>
			</div>
			<div className='col-sm-6'>
				<h2 className='text-center'>{past.Company}</h2>
				<h3 className='text-center'>{past.Name}</h3>
				<h4 className='text-center'>{past.StartDate} - {past.EndDate}</h4>
				<p>{past.Description}</p>
			</div>		
		</div>
export default PastProjects 