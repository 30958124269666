import React from 'react';
import CurrentProjects from '../components/CurrentProjects';
import PastProjects from '../components/PastProjects';
import ProjectsInfo from '../data/ProjectsInfo';

class Projects extends React.Component{
	constructor(){
		super();
		this.state=ProjectsInfo
	}
	render(){
		return(
			<div>
				<h1 className='text-center'>Current Projects</h1>
				<div className='container'>
				<div className='row'>
					{this.state.current.map((current)=>{
						return <CurrentProjects current={current} key={current.id} />
					})}
				</div>
				</div>
				<h1 className='text-center'>Past Projects</h1>
				<div className='row'><p><br/></p></div>
				<div className='row'>
					{this.state.past.map((past)=>{
						return <PastProjects past={past} key={past.id} />
					})}
				</div>
			</div>
			);
	}
}
export default Projects
