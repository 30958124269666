import React from 'react';
import Nav from '../components/Nav';

class PageTemplate extends React.Component{
	render(){
		const parallax = this.props.location.pathname === "/" ? 'parallax' : '';
		return(
			<div>
				<div className={parallax} >
					<Nav location = {this.props.location}/>
				</div>
				<div>
					{this.props.children}
				</div>
			</div>
		);
	}

}
export default PageTemplate
