const CompanyInfo={
		info:[
			{
				id:1,
				name:'Vision',
				image:'./src/images/icons/vision.png',
				text:'Our vision is to see agriculture grow in production and quality through-out Europe and Africa so the countries will be self-reliant in food production, and be a nett exporter of agricultural products.'
			},
			{
				id:2,
				name:'Objective',
				image:'./src/images/icons/objective.png',
				text:'To assist both private agricultural companies, and small scale farmers work in co-operation to mutual benefit to maximise crop yields, improved quality and increased profitability.'
			},
			{
				id:3,
				name:'Principles',
				image:'./src/images/icons/principles.png',
				text:'Teamwork, considering ethnic & gender equality, by collaboration and combining specialized knowledge & experience to develop successful, sustainable mutual beneficial strategies, which maximize economic and social benefits.'
			},
			{
				id:4, name:'Specialization',
				image:'./src/images/icons/specialization.png',
				text:'Crop production included tobacco, maize, wheat and barley. Beef cattle production was part of the integrated farming operations. Horticulture crops were greenhouse flowers.'
			}
			],
		services:[
			{
				id:1,
				name:'Development Work, Agricultural Sector',
				image:'./src/images/icons/AgriculturalDevelopment.png',
				text:'Supporting through guidance, administrational work and experience sharing with non-governmental organizations and agricultural institutions to start up their operations in Ethiopia.'
			},
			{
				id:2,
				name:'Investment Opportunities Initiations',
				image:'./src/images/icons/InvestmentOportunities.png',
				text:'Meset updates its local network through reliable sources in the agricultural sector in Ethiopia and Africa to have the most recent information over new investment opportunities in the country. Support to realise investment opportunities, Meset Consult together with investors/partners are able to implement, administrate and manages the project.'
			},
			{
				id:3,
				name:'Sourcing Agricultural Products',
				image:'./src/images/icons/AgriculturalSourcing.png',
				text:'Mediatory functions to link Ethiopian or foreign commodity producers to retailers overseas especially the Netherlands and the USA.'
			},
			{
				id:4,
				name:'Dairy and Bovine Business Development',
				image:'./src/images/icons/LivestockBusinessDevelopment.png',
				text:'Strategizing towards a successful commercial activity in dairy and bovine industry, through improving efficiency of production, quality and post-production relationships with end customers. As a consultancy company, we ensure to strengthen the governance and competitive position of our customers within the value chain specifically addressing development, upgrading, and sector strengthening in cooperation with international partners.	'
			},
			{
				id:5,
				name:'Training and Matchmaking',
				image:'./src/images/icons/Training.png',
				text:'Through the large pool of skilled professionals, Meset Consult provides trainings for various disciplines in the agricultural sector and link businesses for a symbiotic collaboration and mutual benefits.'
			},
			{
				id:6,
				name:'Business and Development Planning',
				image:'./src/images/icons/BusinessDevelopment.png',
				text:'To make the business a profitable one, Meset Consult analysis the risk factors and the possible forecasted revenues in order to deliver a tailored-made bankable business planning for every start-up or existing agricultural investment in Ethiopia.'
			},
			{
				id:7,
				name:'Tobacco Farming',
				image:'./src/images/icons/Tobacco.png',
				text:'20 years of experience in farm production of both flue cured Virginia tobacco varieties as well as burly air cured varieties. Meset Consult PLC has access to seed varieties and is able to do all farming related operations. This include Curing facilities, Land development, Crop rotations, Irrigation development and leaf storage and grading. Meset Consult PLC has well established network in the tobacco farming and production industry.'
			},
			{
				id:8,
				name:'Horticulture',
				image:'./src/images/icons/Horticulture.png',
				text:'Experience in greenhouse and open-field flower cultivation for export to the EU and strategic collaboration with Dutch partners, forms the basis for hands-on and state-of-the-art technical advice to Sub-Saharan Africa’s emerging horticulture sector. Facilitation of technical training in horticulture/floriculture: crop cultivation, harvest and post-harvest handling and Integrated Pest Management (IPM). MESET further assists companies to prepare for exporting to high-end markets notably EU by advising and training on quality aspects and Maximum Residue Levels (RMLs).'
			},
			{
				id:9,
				name:'Value Chain Development & Study',
				image:'./src/images/icons/ValueChane.png',
				text:'The development intervention (GOs and NGOs) requires the assessment and intervention of the commodities from farm to fork which require value chain studies for upgrading strategies. In this respect Meset has done value chain studies in Ethiopia and other African countries in partnership with other consulting companies.'
			},
			{
				id:10,
				name:'Sugar Cane Production',
				image:'./src/images/icons/SugarCane.png',
				text:'Specialized in sugarcane consulting activities ranging from green field projects design to implementation, including resource analysis & site selection, feasibility & project studies, agricultural engineering, project management, organizational training and operations management. Agricane projects in Africa include Sierra Leone, Mozambique, Zambia, Malawi, Tanzania, Zimbabwe, Swaziland, Kenya, Ghana, Ivory Coast and Mali.'
			}
			]
}
export default CompanyInfo
