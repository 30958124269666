import React from 'react';
import Services from '../components/Services';
import Info from '../components/Info';
import CompanyInfo from '../data/CompanyInfo'

class About extends React.Component{
	constructor(){
		super();
		this.state = CompanyInfo;
	}
	
	render(){
		return(
			<div>
				<div className='container'>
					<div className='row'>						
						<div className='col-sm-6 col-centered'>
							<h1 className='text-center'>Mission Statement</h1>
							<p>To develop agriculture and horticulture business opportunities, assists in the   implementation of these projects, ensure that the projects are placed in a leading position in the selected field. To optimise investment and increase market share by developing, networking, marketing, procurement and distribution systems cost effectively.</p>
						</div>
					</div>
				</div>
				<div className='container'>
					<div className='row'>
						{this.state.info.map((info)=>{
							return <Info key={info.id} info={info} />
						})}					
					</div>
				</div>
				<h1 className='text-center'>Services</h1>
				<div className='container'>
					<div className='row fix'>
						{this.state.services.map((service)=>{
							return <Services key={service.id} service={service} />
						})}
					</div>
				</div>
			</div>
			);
	}
}
export default About