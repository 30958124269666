const TeamData={
			contact:[
			{
				id: 1,
				name:'Cor Jan Zee',
				position:'Senior Consultant',
				image:'./src/images/teamFaces/CorJanZee.jpg',
				mobile:'+31 (0) 645219954',
				email:'c.j.zee@mesetconsult.com',
				skype:'macams1',
				twitter:'https://www.twitter.com/mesetconsult',
				linkedin:'https://www.linkedin.com/in/cjzee/',
				bio:'Born and raised in Zimbabwe with Mechanical Engineering background and 20 years of commercial farming experience in Zimbabwe.  Crop production included tobacco, maize, wheat and barley. Beef cattle production was part of the integrated farming operations. Horticulture crops were greenhouse flowers, as well as outdoor flowers for export, mainly to the Netherlands. Vegetable crops for export to the UK market included English runner beans, and fine beans.'
			},
			{
				id: 3,
				name:'Lorette Zee',
				position:'Agricultural Production Chain Management',
				image:'./src/images/teamFaces/LoretteZee.jpg',
				mobile:'+31 (0) 645219718',
				email:'l.zee@mesetconsult.com',
				skype:'',
				twitter:'',
				linkedin:'https://www.linkedin.com/in/lorette-zee-a3275235/',
				bio:'Entrepreneurial in developing, implementing and leading green field Agricultural business as well as established business through improved agricultural practices in all areas, from farm to market, thereby enhancing the business to gain a better edge and returns on their investment.'
			}
			],
}
export default TeamData
