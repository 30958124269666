import React from 'react';

function Services({service}){
	return(
		<div className='col-sm-4'>
			<img className='img-responsive center-block'src={service.image} height='100' width='100' />
			<h4 className ='text-center'>{service.name}</h4>
			<p>{service.text}</p>
		</div>
	);
}
export default Services