import React from 'react';

function Info({info}){
	return(
		<div className='col-sm-3'>
			<img className='img-responsive center-block'src={info.image} height='100' width='100' />
			<h4 className ='text-center'>{info.name}</h4>
			<p>{info.text}</p>
		</div>
		);
}
export default Info