import React from 'react';
import ReactDom from 'react-dom';
import {Router, Route,IndexRoute,hashHistory} from 'react-router';
import PageTemplate from './pages/PageTemplate';
import Home from './pages/Home';
import About from './pages/About';
// Change this to the new pages
import Projects from './pages/Projects';
import PartneringCompanies from './pages/PartneringCompanies';
import OurTeam from './pages/OurTeam'
import Contact from './pages/Contact';


ReactDom.render(
	<Router onUpdate = {() => window.scrollTo(0, 0)} history = {hashHistory}>
		<Route path='/' component={PageTemplate}>
			<IndexRoute component={Home}></IndexRoute>
			<Route path ='about' component={About}></Route>
			<Route path ='projects' component={Projects}></Route>
			<Route path ='partneringCompanies' component={PartneringCompanies}></Route>
			<Route path ='ourTeam' component={OurTeam}></Route>
			<Route path = 'contact' component ={Contact}></Route>
		</Route>
	</Router>,
	document.getElementById('app'));
