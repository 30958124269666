import React from 'react';

const CurrentProjects=({current})=>
		<div className='col-md-5'>
			<img className="center-block img-responsive" src = {current.Image} height = '150px' width ='300px'/>
			<h2 className='text-center'>{current.Company}</h2>
			<h3 className='text-center'>{current.Name}</h3>
			<h4 className='text-center'>Since {current.StartDate}</h4>
			<p>{current.Description}</p>
		</div>
export default CurrentProjects
