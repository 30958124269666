import React from 'react';

function TeamMemeber({teamMember}){
	const twitter = teamMember.twitter === '' ? '': <a href={teamMember.twitter} target="_blank"><img src='./src/images/icons/twitter.png' height = '25px' width ='25px'/></a>;
	const linkedin = teamMember.linkedin === '' ? '': <a href={teamMember.linkedin} target="_blank"><img src='./src/images/icons/linkedin.png' height = '25px' width ='25px'/></a>;
	return(	
		<div className='row col-sm-6 '>
			<div className='col-sm-5'>
				<img className="img-responsive img-circle" src = {teamMember.image} height = '150px' width ='150px'/>
				
			</div>
			<div className='col-sm-7'>
				<h2 className='text-center'>{teamMember.name}</h2>				
				<h4 className='text-center'>{teamMember.position}</h4>
				<div className='text-center'>

					{twitter}
					{linkedin}
					<tbody>
						  <tr >
						    <td><img src='./src/images/icons/mob.png' height = '25px' width ='25px'/></td>
						    <td>{teamMember.mobile}</td> 
						  </tr>
						  <tr><td><p> </p></td></tr> 
						  <tr>
						    <td><img className='img-responsive' src='./src/images/icons/email.png' height = '25px' width ='25px'/></td>
						    <td>{teamMember.email}</td> 
						  </tr>
						  <tr><td><p> </p></td></tr> 
						  </tbody>				
				</div>
				<p>{teamMember.bio}</p>
			</div>
			
		</div>
	);
}
export default TeamMemeber 