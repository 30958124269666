import React from 'react';

function TableContent({content}){
	return(
			<tbody>
				<tr>
					<td><img className='img-responsive img-circle80' src={content.image} height = '40px' width ='40px'/></td>
					<td>{content.data}</td>
				</tr>
				<tr>
					<td><p> </p></td>
				</tr> 
			</tbody>
	);
}
export default TableContent