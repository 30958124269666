const ProjectsInfo={
			current:[
				{
					id:4,
					Company: 'Source Trace',
					StartDate: 'August 2018',
					Image: './src/images/logos/SourceTrace.jpg',
					Description: 'Source Trace http://www.sourcetrace.com/  is a global leader in developing innovative software and mobile applications for sustainable agriculture and small holder farmer empowerment in inclusiveness. Source Trace technology platform leverages existing wireless data networks to allow companies to capture transactional information at the source, even in remote locations with minimal to no telecommunication infrastructure. The data captures all farmer activities, from crop production, land size, yield estimation, financial transactions and full traceability to end consumer.'
				},
				{
					id:3,
					Company:'AG Aviation Africa',
					StartDate:'December 2017',
					Image:'./src/images/logos/AgAviationAfrica.png',
					Description:'Ag Aviation Africa specializes in aviation solutions, focused on aerial technologies and solutions. Services include aerial crop applications of fertilizers, chemicals and seeds cost effectively and access to crop protection and fertilizing during wet conditions. Through aerial spectral imagery and laser data over agricultural crops from the air, large area surveillance to provide actionable insights to crop performance for local consultants and development agents. Allows data to be collected cost efficiently on area planted; to which crops; yielding detailed information on crop condition, health, and stress factors, including nutrient status, water, disease and pest levels. Geological and soil mapping, general surveillance and fuel transport, are other services provided by Ag Aviation Africa. For more details please contact us for more specific information.'
				}

			],
			past:[
				{
					id: 15,
					Company:'AquaFarmingConsult',
					Name: 'Fish Farming training project – Sudan',
					StartDate: 'March',
					EndDate: 'April 2020',
					Description: 'Participated together with AquaFarmingConsult in the OKP-TMT training titled “Integration of aquaculture and fisheries into the rural water of Sudan program in Gadaref State, Eastern Sudan” for technical training of staff of ZOA Sudan, offered by AquFarmingConsult BV, The Netherlands. The training included; design and present the training program, provided selected topics contributing to theoretical concepts of aquaculture, economic feasibility; horticulture, irrigation and aquaculture',
					Image: './src/images/logos/AquaFarmingConsult.png'
				},
				{
					id: 14,
					Company: 'Feasibility Study Export-oriented Horticulture in Malawi',
					StartDate: 'January 2018',
					EndDate: 'January 2019',
					Image:'',
					Description: 'Conducted a pre-investment study to sustainably develop 300 ha of arable land for horticulture, adjacent to Kamuzu International Airport. The study included: assessment of water resources and soils for their suitability for horticulture. Identification of key horticulture/floriculture crops that can be economically cultivated and exported for specific export markets from the designated area. Estimation of required investments in infrastructure (new; upgrading) to facilitate private investments. Identification and addressing key bottlenecks/challenges for the development of a profitable horticulture sector. Roadmap/recommendations for (facilitating) investments in horticulture. The project aims to prepare for the development of a designated area of 300 ha for export-oriented horticulture. The investment project will promote sustainable trade and investment linkages for Dutch investors and suppliers and other foreign and Malawi investors and will generate forex and employment opportunities in Malawi. The study is commissioned and funded by the Netherlands Ministry of Economic Affairs.'

				},
			 	{
					id:12,
					Company:'Nufoli',
					Name:'Farming Enterprise',
					StartDate:'January 2015',
					EndDate:'June 2018',
					Image:'./src/images/logos/Nufoli.png',
					Description:'Nufoli Investment PLC is an agricultural investment company in Ethiopia pioneered by Meset Consult and Growing for Development B.V. The farming enterprise aims to acquire 1.000 hectares of arable land in Ethiopia for the cultivation of malt barley for Ethiopian breweries and vegetables for the export markets. Initially Meset Consult identified the land and developed a business proposal for which Growing for Development accepted and committed itself to source more investment partners. With the fruitful efforts from both parties, capital was raised to develop the project in the highlands of Ethiopia. The project is currently in implementation phase.'
				},
				{
					id:13,
					Company:'Al Asab Farm PLC',
					Name: 'Farm Fattening, Gode',
					StartDate: 'October 2017',
					EndDate: 'December 2018',
					Image: './src/images/logos/AlsabFarmingPlc.png',
					Description: 'Al Asab Farming PLC acquired 2,000 hectares of farm land and required a business feasibility plan with the objective to produce animal feed crops under irrigation using the water from the Shabele river in Gode, Ethiopia. The feed produced is used on-farm to fatten cattle destined for their partner slaughter house who export internationally. Business plan includes costing all farming operations, equipment sourcing and cost benefit analysis.'
				},
				{
					id:11,
					Company:'Herb and Spice Initiative',
					Name:'Sustainable Spice Production Ethiopia',
					StartDate:'November 2014',
					EndDate:'October 2017',
					Image:'./src/images/logos/HerbsAndSpies.jpg',
					Description:'After attendance at a couple of herbs and spices missions organized by NABC and Embassy of the Netherlands in Ethiopia, Meset Consult followed with organizing potential stakeholders into developing a strategic way forward for herbs and spices production in Ethiopia. As a result, a project proposal was developed with the help of Tropical Institute of Amsterdam and Dutch spice importers to cultivate spices in Ethiopia which are sustainable for the small scale farmer’s economy and develop a reliable profitable export market to the Netherland. The project proposal is in progress.'
				},
				{
			 		id:10,
			 		Company:'NCBA CLUSA',
			 		Name:'Value Chain Study of Sesame',
			 		StartDate:'July 2016',
			 		EndDate:'September 2016',
			 		Image:'./src/images/logos/NCBACLUSA.png',
			 		Description:'NCBA CLUSA is procuring the consulting service of Meset Consult PLC to train the Malawi Ministry of Agriculture front-line staff as well as lead farmers in Nsanje, Chikwawa and Blantyre in proper seed breeding, good quality sesame seeds, and recommended integrated pest control for sesame, sesame farm management and post-harvest handling. This is expected to improve both productivity and production qualities of sesame, increasing ability of over 20,000 small holders to meet local, regional and international market quality and quantity demands for sesame.'
			 	},
			 	{
			 		id:9,
			 		Company:'Wageningen and Addis Chamber of Commerce',
			 		Name:'Business Opportunity Report for Vegetable Production and Export from Ethiopia',
			 		StartDate:'October 2015',
			 		EndDate:'February 2016',
			 		Image:'./src/images/logos/Wageningen&Addis.png',
			 		Description:'Business Opportunities Report is a summarized guidance booklet aimed at attracting foreign and indigenous investment in Ethiopia for producing fruit and vegetable for export purposes. The guidance booklet provides an overview of the local situation in Ethiopia on the critical issues that influence production and investments such as land availability, climate suitability, overall risks of business, financial incentives offered from the government, European export market analysis and most commercially feasible products to grow in Ethiopia for European markets. The book is expected to be released from Wageningen University by March 2016.'
			 	},
			 	{
			 		id:8,
			 		Company:'Agriterra',
			 		Name:'Agri-Business Advisment',
			 		StartDate:'May 2012',
			 		EndDate:'December 2015',
			 		Image:'./src/images/logos/Agriterra.png',
			 		Description:'Agriterra is a Dutch development agri-agency established in Arnhem specializing in farmers’ cooperative development. Agriterra has clients in Africa, Asia and Latin America See for more details www.Agriterra.org Meset was contracted by Agriterra from May 2012 to Dec 2014, to establish Agriterra operations in Ethiopia. Major achievement was to increase support to four farmer cooperative organizations to 24 in four regions; Tigray, Amhare, Oromia and SNNP. Support focused on organizational capacity development of union organizations and enable cooperatives and their members to better access finance and markets for their products. January 2015 to December 2015, Agriterra registered their own legal entity in Ethiopia, and Meset provided support during the transition period'
			 	},
			 	{
			 		id:7,
			 		Company:'EHPEA',
			 		Name:'International Market Development',
			 		StartDate:'October 2014',
			 		EndDate:'March 2015', Image:'./src/images/logos/EHPEA.png',
			 		Description:'Ethiopia’s export market is increasing rapidly and the investing climate for foreign as well as Ethiopian owned farms is positive in the country. Therefore the Ethiopian Horticulture Producers and Exporters Association is to promote Ethiopian produce in the international arena. Meset Consult role was to assist linking EHPEA members with international market linkages (buyer targeting), branding, project baseline studies, Ethiopian product and producer positioning against competitors and presentations and communications.'
			 	},
			 	{
			 		id:6,
			 		Company:'Mechanization',
			 		Name:'Ethiopian Agricultural Mechanization situation',
			 		StartDate:'June 2014',
			 		EndDate:'October 2014',
			 		Image:'./src/images/logos/Mechanization.jpg',
			 		Description:'Researching the availability, the needs and capacity of agriculture mechanization in Ethiopia. Conducting the research on behalf of Agriterra/C4C (Cooperatives for Change) with the purpose to propose an effective rental service strategy from unions to farmers. Additionally, the local supplier with their services are researched and the different mechanization requirements in different cultural, farming practices and climatic areas and regions.'
			 	},
			 	{
			 		id:5,
			 		Company:'Demonstration',
			 		Name:'Small and Large Scale Farming Equipment',
			 		StartDate:'June 2014',
			 		EndDate:'August 2014',
			 		Image:'./src/images/logos/Demonstration.jpg',
			 		Description:'The Meset team organized a one month demonstration tour in 6 locations in Amhara region, 2 in Oromia region, and 3 in Tigray region, Ethiopia. The idea was to demonstrate big scale and small scale farming machinery to the farmers with low exposure to mechanization. The goal is awareness raising and mentality change through demonstrations. The machinery demonstrated consisted of 2-wheel tractors up to 120 Hp tractors. They performed soil tillage, power harrowing and row planting. The volunteers who agreed to take the machineries the tour ere local dealerships from Addis Ababa.'
			 	},
			 	{
			 		id:4,
			 		Company:'Animal Feed Production and Related Farming',
			 		Name:'',
			 		StartDate:'November 2011',
			 		EndDate:'January 2013',
			 		Image:'./src/images/logos/AnimalFeed.jpg',
			 		Description:'Research into the animal feed industry was requested by Akaki Animal Feed Plant PLC and Oral Agro Industries PLC. This study includes all aspects of the animal feed value chain from farm production to consumer, and involves multi professional disciplines. The objective is to understand the current bottlenecks and opportunities and what action steps are required to develop a viable and sustainable value chain for the animal feed sector incorporating the commercial and small scale sectors. The result would be developing viable business models which are attractive for private sector investment, equity capital and bank support. The outcome would be an integrated and sustainable value chain which would significantly contribute to food security at a competitive market price in Ethiopia and East Africa.'
			 	},
			 	{
			 		id:3,
			 		Company:'PPP-Oilseeds',
			 		Name:'Mechanization and Sustainable',
			 		StartDate:'July 2011',
			 		EndDate:'Febuary 2012',Image:'./src/images/logos/PppOilSeeds.jpg',
			 		Description:'The Ethiopian/Netherlands PPP-oilseeds partnership approved a sesame mechanization and fertilization trial at Humera Agriculture Research Centre (HARC). The project partners were Ethiopian Agriculture Research Institute (EARI), Agro-Consult and AETSSCO. The aim was to set up different trials for sesame crop, for yield evaluation and comparison between different fertilizer rates, herbicides rates, seed dressings and mechanized harvesting practices. The goal was to find the most efficient treatment combination and crop rotation for sesame growing.For further information please request from Meset Consult to see ‘Results - Mechanization & Sustainable Agriculture Practises – Sesame’.'
			 	},
			 	{
			 		id:2,
			 		Company:'Cordaid',
			 		Name:'Chain Empowerment of Sesame Producers and Organizations',
			 		StartDate:'August 2010',
			 		EndDate:'December 2012',
			 		Image:'./src/images/logos/Cordaid.png',
			 		Description:'This project was a joint project with Meset Consult, FFARM PLC and Target Business Consultants PLC. The target organization is Setit Union Humera, who has 14 member cooperatives and 9,000 family households whose primary livelihood revolves around sesame production. The project aims at improving the socio and economic fundamentals for the farmers and their organizations by assisting with financial and management training, improved agronomy and farming practices, linkage to markets and direct exports as well as sourcing and assisting with farmer input finance.'
			 	},
			 	{
			 		id:1,
			 		Company:'Selet Hulling PLC',
			 		Name:'Humera',
			 		StartDate:'June 2008',
			 		EndDate:'May 2010',
			 		Image:'./src/images/logos/SeletHulling.jpg',
			 		Description:'Ethio/Dutch “Joint-venture” company, Selet Hulling PLC in Humera specializes in “organic” sesame seed for export and is partly funded by a PSOM grant from the Netherlands. Selet Hulling installed a modern “Hulling” plant in Addis Ababa which HACCP certified. In the future the company will expand their process operations. A large portion of the sesame production relies on small scale farmer production. To ensure Selet Hulling PLC is able to compete in a competitive international market, Meset Consult was hired to assist in improving the quality and consistency of the sesame seed sourced from Humera through the following expertize: Establishing an organic farming operation in sesame seed production (300 hectares) and training provision for “Organic” farming practices, and assist in the certification for Organic production.'
			 	},
			 	]
}
export default ProjectsInfo
