import React from 'react';
import CompanyPartners from '../components/CompanyPartners';
import CompanyData from '../data/PartnersInfo';


class PartneringCompanies extends React.Component{
	constructor(){
		super();
		this.state = {CompanyData}

	}
	render(){
		return(
			<div className ='container'>
				<div className='row'>	
					<h1 className='text-center'>Partners</h1>
					<div className='row fix'>
						{this.state.CompanyData.partners.map((partner)=>{
							return <CompanyPartners partner={partner} key={partner.id} />
						})}
					</div>
				</div>

			</div>
			);
	}
}
export default PartneringCompanies