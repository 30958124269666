import React from 'react';
import TeamMember from '../components/TeamMember';
import TeamData from '../data/TeamData';

class OurTeam extends React.Component{
	constructor(){
		super();
		this.state = {TeamData}
	}
	render(){
		return(
			<div className = 'container'>
				<div className="row">
					<h1 className='text-center'>Meet our Team</h1>
					<div className='row fix'>
					{this.state.TeamData.contact.map((teamMember)=>{
						return <TeamMember teamMember = {teamMember} key = {teamMember.id} />
					})}
					</div>
				</div>
			</div>
			);
	}
}
export default OurTeam
