import React from 'react';
import { Map, TileLayer } from 'react-leaflet';
import TableContent from '../components/TableContent';
import ContactInfo from '../data/ContactInfo.js';
const office = [51.9244201, 4.4777325];

class Contact extends React.Component{
		constructor(){
			super();
			this.state = {ContactInfo}
		}
		render(){
		return(
			<div className= 'container'>
				<h1 className='text-center'>Contact Us<br/></h1>
				<div className='row'><p><br/></p></div>
				<div className='row'>
					<div className='col-sm-4'>
						<table >
							<tbody>
								<tr>
									<td></td>
									<td><img className='img-responsive img-circle80' src='./src/images/icons/Netherlands.png' height = '100x' width ='100px'/></td>
								</tr>
							</tbody>
								{this.state.ContactInfo.Netherlands.map((details)=>{
									return <TableContent content = {details} key = {details.id} />
								})}
						</table>
					</div>
					<div className='col-sm-4'>
						<Map center={office} zoom={10}>
							 <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"/>

					  	</Map>
				  	</div>
			  	</div>
			</div>
			);
	}
}
export default Contact
