import React from 'react';
import {Link} from 'react-router';

class Nav extends React.Component{
	constructor() {
	    super()
	    this.state = {
	      collapsed: true,
	    };
  	}
	toggleCollapse() {
	    const collapsed = !this.state.collapsed;
	    this.setState({collapsed});
  	}

	render(){
		const { location } = this.props;
	    const { collapsed } = this.state;
	    const aboutClass = location.pathname.match(/^\/about/) ? 'active' :'';
	    const projectsClass = location.pathname.match(/^\/projects/) ? 'active' :'';
	    const partneringCompaniesClass = location.pathname.match(/^\/partneringCompanies/) ? 'active' :'';
	    const ourTeamClass = location.pathname.match(/^\/ourTeam/) ? "active" : "";
	    const contactClass = location.pathname.match(/^\/contact/) ? "active" : "";
	    const navClass = collapsed ? "collapse" : "";
	    const nav = location.pathname === "/" ? 'navbar-home':'navbar-default';
		return (
				<nav className={"navbar "+nav+" navbar-fix-top"} role="navigation">
					<div className="container">
	          			<div className="navbar-header">
	            			<button type="button" className="navbar-toggle" onClick={this.toggleCollapse.bind(this)} >
				              <span className="sr-only">Toggle navigation</span>
				              <span className="icon-bar"></span>
				              <span className="icon-bar"></span>
				              <span className="icon-bar"></span>
	            			</button>
	         			 </div>
	     			 </div>

					<div className ={"navbar-collapse "+navClass} id="bs-example-navbar-collapse-1">
						<ul className="nav navbar-nav navbar-left col-sm-1">
							<Link to='/' onClick={this.toggleCollapse.bind(this)}><img src='./src/images/logos/Meset.png' height='100px' width='175px'/></Link>
						</ul>
						<ul className="nav navbar-nav navbar-right">
							<li className ={aboutClass}>
								<Link to='about' onClick={this.toggleCollapse.bind(this)}>About</Link>
							</li>
							<li className={projectsClass}>
								<Link to= 'projects' onClick={this.toggleCollapse.bind(this)}>Projects</Link>
							</li>
							<li className={partneringCompaniesClass}>
								<Link to= 'partneringCompanies' onClick={this.toggleCollapse.bind(this)}>Partner Companies</Link>
							</li>
							 <li className={ourTeamClass}>
								<Link to='ourTeam' onClick={this.toggleCollapse.bind(this)}>Our Team</Link>
							</li>
							<li className={contactClass}>
								<Link to='contact' onClick={this.toggleCollapse.bind(this)}>Contact</Link>
							</li>
						</ul>
					</div>
				</nav>
			);
	}

}
export default Nav
