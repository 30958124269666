const PartnersInfo ={
	partners:[
		{
			id: 3,
			name: 'Source Trace',
			image: './src/images/logos/SourceTrace.jpg',
			info: 'Source Trace http://www.sourcetrace.com/  is a global leader in developing innovative software and mobile applications for sustainable agriculture and small holder farmer empowerment in inclusiveness. Source Trace technology platform leverages existing wireless data networks to allow companies to capture transactional information at the source, even in remote locations with minimal to no telecommunication infrastructure. The data captures all farmer activities, from crop production, land size, yield estimation, financial transactions and full traceability to end consumer.'
		},
		{
			id:4,
			name:'AG Aviation Africa',
			image:'./src/images/logos/AgAviationAfrica.png',
			info: 'Ag Aviation Africa specializes in aviation solutions, focused on aerial technologies and solutions. Services include aerial crop applications of fertilizers, chemicals and seeds cost effectively and access to crop protection and fertilizing during wet conditions. Through aerial spectral imagery and laser data over agricultural crops from the air, large area surveillance to provide actionable insights to crop performance for local consultants and development agents. Allows data to be collected cost efficiently on area planted; to which crops; yielding detailed information on crop condition, health, and stress factors, including nutrient status, water, disease and pest levels. Geological and soil mapping, general surveillance and fuel transport, are other services provided by Ag Aviation Africa. For more details please contact us for more specific information. Drone services, in the agricultural field providing disease detection, aerial overviews, cropping area and yield prediction, and crop spraying are additional services currently being launched, to compliment services to the agriculture sector. Appropriate technology which are considerate of the environment. Assist in planning, development and farm management where necessary.'
		},
		{
			id:1,
			name:'Agricane',
			image:'./src/images/logos/Agricane.png',
			info:'Agricane is an Agricultural Engineering and Development company that aims to provide a service that will make a sustainable diffrence in African Agriculture, with experinced professionals in planning, Design, Development, Training and Management-all who have deep roots and a proven knowledge of African conditions. Agricane have worked in over 20 countries in Africa and we have formed Subsidiary companies in Zimbabwe, Malawi, South Africa, Tanzania, Sierra Leone and Moçambique to tacle the multitude of opportunities presented.'
		},
		{
			id:5,
			name:'ENTRY2MARKET',
			image:'./src/images/logos/entre2market.jpg',
			info:'ENTRY2MARKET is an innovative, international trading and consulting agency based in the Netherlands. We aim to guide you through the entire trade process. From finding new products or projects and intermediating between you and new buyers or partners, to coördinating marketing activities and providing sales and legal advice.'
		}

	]
}
export default PartnersInfo
