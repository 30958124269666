import React from 'react';
import Services from '../components/Services';
import CompanyInfo from '../data/CompanyInfo';
import TeamData from '../data/TeamData';
import TeamMember from '../components/TeamMember';
import {Link} from 'react-router';

class Home extends React.Component{
	constructor(){
		super();
		this.state = {CompanyInfo,TeamData}
	}
	render(){
		return(
			<div>
				<div className='container'>
					<div className='row'>
						<h1 className='text-center'>
							<Link to='about'> Welcome To Meset</Link>
						</h1>
						<div className = 'row fix'>
						{this.state.CompanyInfo.services.map((service)=>{
								return <Services key={service.id} service={service} />
						})}
						</div>
					</div>
				</div>
				<div className ='teamBackgound'>
					<h1 className='text-center'>
						<Link to='ourTeam'>Meet the Team</Link>
					</h1>
					<div className = 'container'>
						<div className='row'>
						{this.state.TeamData.contact.map((teamMember)=>{
							return <TeamMember teamMember = {teamMember} key = {teamMember.id} />
						})}
						</div>
					</div>
				</div>
			</div>
			);
	}
}
export default Home
